import { css } from "@emotion/react";

export const columnGap4Css = css`
  column-gap: 4px;
`;

export const rowGap8Css = css`
  row-gap: 8px;
`;

export const columnGap8Css = css`
  column-gap: 8px;
`;

export const columnGap10Css = css`
  column-gap: 10px;
`;

export const rowGap10Css = css`
  row-gap: 10px;
`;

export const columnGap12Css = css`
  column-gap: 12px;
`;

export const rowGap16Css = css`
  row-gap: 16px;
`;

export const columnGap16Css = css`
  column-gap: 16px;
`;

export const rowGap24Css = css`
  row-gap: 24px;
`;
