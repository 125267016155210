import { useMemo } from "react";
import { useParams } from "react-router-dom";
import AUTH_STATUS from "constants/auth";
import ROUTE_PATHS from "constants/routePaths";
import useAuth from "context/authContext/useAuth";
import useGetUserDataQuery from "hookQueries/useGetUserDataQuery";
import selectRoleField from "hookQueries/useGetUserDataQuery/selectRoleField";
import useIsObserverRole from "hooks/useIsObserverRole";
import LINKS from "./NavLinks/links";

const DEFAULT_LINKS = [
  { to: ROUTE_PATHS.root, name: "home" },
  { to: ROUTE_PATHS.terms, name: "company" },
  { to: ROUTE_PATHS.policy, name: "marvelous policy" },
];

const GET_USER_DATA_QUERY_OPTIONS = {
  select: selectRoleField,
};

export default function useLinks() {
  const { authStatus } = useAuth();
  const { isLoading, data: role } = useGetUserDataQuery(GET_USER_DATA_QUERY_OPTIONS);
  const isObserverRole = useIsObserverRole();
  const { reportId } = useParams();

  return useMemo(() => {
    if (isLoading || authStatus === AUTH_STATUS.pending) return [];

    if (authStatus === AUTH_STATUS.authorized) {
      return LINKS.reduce((acc, { roles, ...link }) => {
        if (roles.includes(role)) {
          acc.push({ ...link, isDisabled: isObserverRole && link.name !== "dashboard" });
        }

        return acc;
      }, []);
    }

    if (reportId) {
      return LINKS.reduce((acc, { roles, ...link }) => {
        if (link.name !== "user management") {
          acc.push({ ...link, isDisabled: link.name !== "dashboard" });
        }

        return acc;
      }, []);
    }

    return DEFAULT_LINKS;
  }, [authStatus, isLoading, isObserverRole, reportId, role]);
}
