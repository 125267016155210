/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { dividerCss } from "assets/css/divider.css";
import { menuItemCss } from "assets/css/linkCss";
import { ReactComponent as ParentCompaniesIcon } from "assets/icons/ParentCompaniesIcon.svg";
import ROUTE_PATHS from "constants/routePaths";
import MenuDropdown from "../../MenuDropdown";

export default function ParentCoLinkMenu({ isOpen }) {
  return (
    <MenuDropdown isOpen={isOpen}>
      <NavLink to={ROUTE_PATHS.parentCompanies} css={menuItemCss}>
        <ParentCompaniesIcon />

        <span>Parent Co.</span>
      </NavLink>

      <div css={dividerCss} />

      <NavLink to={ROUTE_PATHS.brands} css={menuItemCss}>
        <ParentCompaniesIcon />

        <span>Brands</span>
      </NavLink>

      <NavLink to={ROUTE_PATHS.categories} css={menuItemCss}>
        <ParentCompaniesIcon />

        <span>Parent Categories</span>
      </NavLink>
    </MenuDropdown>
  );
}

ParentCoLinkMenu.propTypes = {
  isOpen: PropTypes.bool,
};
