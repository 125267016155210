/** @jsxImportSource @emotion/react */
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import { columnGap10Css } from "assets/css/gaps.css";
import AUTH_STATUS from "constants/auth";
import ROUTE_PATHS from "constants/routePaths";
import useAuth from "context/authContext/useAuth";
import { linkCss, wrapperCss } from "./index.css";

export default function Footer() {
  const { authStatus } = useAuth();
  const { current: currentYear } = useRef(new Date().getFullYear());
  const isAuthorized = authStatus === AUTH_STATUS.authorized;

  return (
    <footer css={wrapperCss} className="flex-a-c-j-sb text">
      <div css={columnGap10Css} className="flex">
        {isAuthorized && (
          <>
            <NavLink css={linkCss} to={ROUTE_PATHS.admin}>
              Home
            </NavLink>

            <NavLink css={linkCss} to={ROUTE_PATHS.root}>
              Company
            </NavLink>
          </>
        )}

        <NavLink css={linkCss} to={ROUTE_PATHS.terms}>
          Terms & Conditions
        </NavLink>

        <NavLink css={linkCss} to={ROUTE_PATHS.policy}>
          Privacy Policy
        </NavLink>
      </div>

      <div css={columnGap10Css} className="flex">
        <span>{currentYear}</span>

        <NavLink css={linkCss} to={ROUTE_PATHS.root}>
          Marvelous.works
        </NavLink>
      </div>
    </footer>
  );
}
