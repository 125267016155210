/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useToggle } from "react-use";
import Drawer from "rc-drawer";
import { ClassNames } from "@emotion/react";
import { ReactComponent as MenuIcon } from "assets/icons/Menu.svg";
import AUTH_STATUS from "constants/auth";
import useAuth from "context/authContext/useAuth";
import useGetUserDataQuery from "hookQueries/useGetUserDataQuery";
import usePreventScroll from "hooks/usePreventScroll";
import LoggedSidebarContent from "./LoggedSidebarContent";
import NavLinks from "../NavLinks";
import Sidebar from "../Sidebar";
import { drawerBtnCss, drawerCss, headerWrapperCss } from "./index.css";

export default function MobileHeader() {
  const { authStatus } = useAuth();
  const { data: user } = useGetUserDataQuery();
  const { pathname } = useLocation();
  const [drawer, toggleDrawer] = useToggle(false);

  useEffect(() => {
    toggleDrawer(false);
  }, [pathname, toggleDrawer]);

  usePreventScroll(drawer);

  const isAuthenticated = authStatus === AUTH_STATUS.authorized;

  return (
    <header className="flex-column">
      <div css={headerWrapperCss} className="flex-a-c-j-st main-text-grey-bold">
        <button type="button" css={drawerBtnCss} onClick={toggleDrawer} className="flex-a-c">
          <MenuIcon />
        </button>
      </div>

      <ClassNames>
        {({ cx, css }) => (
          <Drawer
            rootClassName={cx(css(drawerCss))}
            placement="left"
            width="200px"
            ease="ease-in-out"
            open={drawer}
            onClose={toggleDrawer}
            level={null}
          >
            <Sidebar>
              {isAuthenticated && <LoggedSidebarContent email={user?.email} />}

              <NavLinks />
            </Sidebar>
          </Drawer>
        )}
      </ClassNames>
    </header>
  );
}
