import { Link, useParams } from "react-router-dom";
import { ReactComponent as MarvelousLogo } from "assets/icons/Logo.svg";
import AUTH_STATUS from "constants/auth";
import ROUTE_PATHS from "constants/routePaths";
import useAuth from "context/authContext/useAuth";

export default function LogoLink() {
  const { reportId } = useParams();
  const { authStatus } = useAuth();
  const isAuthenticated = authStatus === AUTH_STATUS.authorized;
  const isReportPage = !!reportId;
  const to = isAuthenticated || isReportPage ? ROUTE_PATHS.admin : ROUTE_PATHS.root;

  return (
    <Link to={to}>
      <MarvelousLogo />
    </Link>
  );
}
